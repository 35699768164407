<app-breadcrumb [title]="(id.length>10?'Actualizar':'Crear')+' Cliente'" [items]="['Clientes' ]"
    [active_item]="'Crear'"></app-breadcrumb>
<div class="container-fluid">
    <div class="edit-profile">
        <div class="row">
            <div class="col-xl-12 card">
                <div class="card-header">
                    <h4 class="card-title mb-0"> {{ id.length>10?'Actualizar':'Crear' }} Cliente</h4>
                    <div class="card-options">
                        <a class="card-options-collapse" href="javascript:void(0)" data-bs-toggle="card-collapse"><i
                                class="fe fe-chevron-up"></i></a><a class="card-options-remove"
                            href="javascript:void(0)" data-bs-toggle="card-remove"><i class="fe fe-x"></i></a>
                    </div>
                </div>
                <div class="card-body">
                    <div class="tabbed-card">
                        <ul ngbNav [activeId]="1" [tabIndex]="navSelected" ite
                            class="pull-right nav nav-tabs border-tab nav-primary">
                            <li [ngbNavItem]="1" (click)="changeTab('1')">
                                <a ngbNavLink><i class="icofont icofont-man-in-glasses"></i> información</a>
                                <ng-template ngbNavContent>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="2" (click)="changeTab('2')">
                                <a ngbNavLink><i class="icofont icofont-contacts"></i>Referencias</a>
                                <ng-template ngbNavContent>
                                </ng-template>
                            </li>
                        </ul>
                    </div>
                    <form [formGroup]="editProfile" *ngIf="navSelected=='1'">
                        <div class="row">
                            <div class="col-12 m-0 p-0">
                                <h6 class="m-0 p-0">Datos Personales</h6>
                                <hr>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Mercado o Zona</label>
                                    <select class="form-control" formControlName="mar_code" [ngClass]="{
                                        'is-invalid': submitted && form['mar_code'].errors
                                      }">
                                        <option value="" selected>--Seleccione--</option>
                                        <option *ngFor="let item of marks" [value]="item.mar_code"> {{item.mar_name}}
                                        </option>
                                    </select>
                                    <p *ngIf="submitted && form['mar_code'].errors" class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Nombre completo</label>
                                    <input class="form-control" type="text" placeholder="Nombre completo"
                                        formControlName="cli_full_name" [ngClass]="{
                                            'is-invalid': submitted && form['cli_full_name'].errors
                                          }" />
                                    <p *ngIf="submitted && form['cli_full_name'].errors" class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">DUI</label>
                                    <input class="form-control" type="text" placeholder="DUI" formControlName="cli_dui" mask="00000000-0"
                                        [ngClass]="{
                                        'is-invalid': submitted && form['cli_dui'].errors
                                      }" />
                                    <p *ngIf="submitted && form['cli_dui'].errors" class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">Fecha de expedicion</label>
                                <div class="input-group mb-3">
                                    <input class="form-control" type="text" placeholder="yyyy-mm-dd" mask="0000-M0-d0"
                                        [dropSpecialCharacters]="false" formControlName="cli_dui_date_expedition"
                                        [ngClass]="{
                                            'is-invalid': submitted && form['cli_dui_date_expedition'].errors
                                          }" />
                                </div>
                                <p *ngIf="submitted && form['cli_dui_date_expedition'].errors"
                                    class="is-invalid text-danger">
                                    Este dato es requerido
                                </p>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Lugar de expedicion</label>
                                    <input class="form-control" type="text" placeholder="Lugar de expedicion"
                                        formControlName="cli_place_expedition" [ngClass]="{
                                        'is-invalid': submitted && form['cli_place_expedition'].errors
                                      }" />
                                    <p *ngIf="submitted && form['cli_place_expedition'].errors"
                                        class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">Fecha de expiracion</label>
                                <div class="input-group mb-3">
                                    <input class="form-control" type="text" placeholder="yyyy-mm-dd" mask="0000-M0-d0"
                                        [dropSpecialCharacters]="false" formControlName="cli_dui_date_expiration"
                                        [ngClass]="{
                                            'is-invalid': submitted && form['cli_dui_date_expiration'].errors
                                          }" />
                                </div>
                                <p *ngIf="submitted && form['cli_dui_date_expiration'].errors"
                                    class="is-invalid text-danger">
                                    Este dato es requerido
                                </p>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Genero</label>
                                    <select class="form-control" formControlName="gen_code" [ngClass]="{
                                        'is-invalid': submitted && form['gen_code'].errors
                                      }">
                                        <option value="" selected>--Seleccione--</option>
                                        <option *ngFor="let item of genders" [value]="item.gen_code"> {{item.gen_names}}
                                        </option>
                                    </select>
                                    <p *ngIf="submitted && form['gen_code'].errors" class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <label class="form-label">Fecha de nacimiento</label>
                                <div class="input-group mb-3">
                                    <input class="form-control" type="text" placeholder="yyyy-mm-dd" mask="0000-M0-d0"
                                        [dropSpecialCharacters]="false" formControlName="cli_birth_date" [ngClass]="{
                                            'is-invalid': submitted && form['cli_birth_date'].errors
                                          }" />
                                </div>
                                <p *ngIf="submitted && form['cli_birth_date'].errors" class="is-invalid text-danger">
                                    Este dato es requerido
                                </p>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">NIT</label>
                                    <input class="form-control" type="text" placeholder="NIT" formControlName="cli_nit"
                                        [ngClass]="{
                                        'is-invalid': submitted && form['cli_nit'].errors
                                      }" />
                                    <p *ngIf="submitted && form['cli_nit'].errors" class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Estado Civil</label>
                                    <select class="form-control" formControlName="cis_code" [ngClass]="{
                                        'is-invalid': submitted && form['cis_code'].errors
                                      }">
                                        <option value="" selected>--Seleccione--</option>
                                        <option *ngFor="let item of civilStatus" [value]="item.cis_code">
                                            {{item.cis_names}} </option>
                                    </select>
                                    <p *ngIf="submitted && form['cis_code'].errors" class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">No. Hijos</label>
                                    <input class="form-control" type="text" placeholder="No. Hijos"
                                        formControlName="cli_children_number" [ngClass]="{
                                            'is-invalid': submitted && form['cli_children_number'].errors
                                          }" />
                                    <p *ngIf="submitted && form['cli_children_number'].errors"
                                        class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Nombre conyuge</label>
                                    <input class="form-control" type="text" placeholder="Nombre conyuge"
                                        formControlName="cli_spouse_name" [ngClass]="{
                                            'is-invalid': submitted && form['cli_spouse_name'].errors
                                          }" />
                                    <p *ngIf="submitted && form['cli_spouse_name'].errors"
                                        class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Profesion u oficio</label>
                                    <select class="form-control" formControlName="prf_code" [ngClass]="{
                                        'is-invalid': submitted && form['prf_code'].errors
                                      }">
                                        <option value="" selected>--Seleccione--</option>
                                        <option *ngFor="let item of profession" [value]="item.prf_code">
                                            {{item.prf_names}} </option>
                                    </select>
                                    <p *ngIf="submitted && form['prf_code'].errors" class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Teléfono</label>
                                    <input class="form-control" type="text" placeholder="Teléfono"  mask="0000-0000"
                                        formControlName="cli_cell_phone" [ngClass]="{
                                            'is-invalid': submitted && form['cli_cell_phone'].errors
                                          }" />
                                    <p *ngIf="submitted && form['cli_cell_phone'].errors"
                                        class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Nivel educativo</label>
                                    <select class="form-control" formControlName="edl_code" [ngClass]="{
                                        'is-invalid': submitted && form['edl_code'].errors
                                      }">
                                        <option value="" selected>--Seleccione--</option>
                                        <option *ngFor="let item of education" [value]="item.edl_code">
                                            {{item.edl_names}} </option>
                                    </select>
                                    <p *ngIf="submitted && form['edl_code'].errors" class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Correo</label>
                                    <input class="form-control" type="text" placeholder="Correo electronico"
                                        formControlName="cli_email" [ngClass]="{
                                            'is-invalid': submitted && form['cli_email'].errors
                                          }" />
                                    <p *ngIf="submitted && form['cli_email'].errors" class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>



                            <div class="col-12 m-0 p-0">
                                <br>
                                <h6 class="m-0 p-0">Datos de fondos</h6>
                                <hr>
                            </div>

                            <div class="col-md-2">
                                <div class="mb-3">
                                    <label class="form-check-label" for="flexSwitchCheckDefault">¿Es contribuyente
                                        fiscal?</label>
                                    <div class="form-check form-switch checkbox-lg">
                                        <input class="form-check-input" type="checkbox"
                                            [checked]="form['cli_is_taxpayer'].value" formControlName="cli_is_taxpayer"
                                            id="cli_is_taxpayer">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">No. registro contribuyente de IVA:</label>
                                    <input class="form-control" type="text"
                                        placeholder="No. registro contribuyente de IVA"
                                        formControlName="cli_no_taxpayer" [ngClass]="{
                                        'is-invalid': submitted && form['cli_no_taxpayer'].errors
                                      }" />
                                    <p *ngIf="submitted && form['cli_no_taxpayer'].errors"
                                        class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Promedio de movimientos de fondos por mes</label>
                                    <!-- <div class="form-check form-switch checkbox-lg">
                                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                    </div> -->
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="mb-3">
                                    <label class="form-check-label" for="flexSwitchCheckDefault"> $500.00</label>
                                    <div class="form-check form-switch checkbox-lg">
                                        <input class="form-check-input" type="checkbox" 
                                            formControlName="cli_mount_month_500" [(ngModel)]="cli_mount_month_500"
                                            id="flexSwitchCheckDefault">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="mb-3">
                                    <label class="form-check-label" for="flexSwitchCheckDefault"> $1,000.00</label>
                                    <div class="form-check form-switch checkbox-lg">
                                        <input class="form-check-input" type="checkbox" 
                                            formControlName="cli_mount_month_1000" [(ngModel)]="cli_mount_month_1000"
                                            id="flexSwitchCheckDefault">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="mb-3">
                                    <label class="form-check-label" for="flexSwitchCheckDefault"> $3,500.00</label>
                                    <div class="form-check form-switch checkbox-lg">
                                        <input class="form-check-input" type="checkbox"  
                                            formControlName="cli_mount_month_3500"  
                                            id="flexSwitchCheckDefault">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="mb-3">
                                    <label class="form-check-label" for="flexSwitchCheckDefault"> N/A</label>
                                    <div class="form-check form-switch checkbox-lg">
                                        <input class="form-check-input" type="checkbox"  
                                            formControlName="cli_mount_month_na"  
                                            id="flexSwitchCheckDefault">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="mb-3">
                                    <label class="form-label" for="flexSwitchCheckDefault">¿Posee otros
                                        ingresos?</label>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="mb-3">
                                    <div class="form-check form-switch checkbox-lg">
                                        <label class="form-check-label" for="flexSwitchCheckDefault"> Si</label>
                                        <input class="form-check-input" type="checkbox"
                                            [checked]="cli_have_other_incomer" id="flexSwitchCheckDefault"
                                            formControlName="cli_have_other_incomer">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="mb-3">
                                    <div class="form-check form-switch checkbox-lg">
                                        <label class="form-check-label" for="flexSwitchCheckDefault"> No</label>
                                        <input class="form-check-input" type="checkbox"
                                            [checked]="!cli_have_other_incomer" id="flexSwitchCheckDefault"
                                            formControlName="cli_have_other_incomer">
                                    </div>
                                </div>
                            </div>



                            <div class="col-12 m-0 p-0">
                                <br>
                                <h6 class="m-0 p-0">Datos del negocio</h6>
                                <hr>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Tipo de negocio</label>
                                    <input class="form-control" type="text" placeholder="Tipo de negocio"
                                        formControlName="cli_bussiness_tipe" [ngClass]="{
                                        'is-invalid': submitted && form['cli_bussiness_tipe'].errors
                                      }" />
                                    <p *ngIf="submitted && form['cli_bussiness_tipe'].errors"
                                        class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="mb-3">
                                    <label class="form-label">Tiempo de estar en el negocio (años)</label>
                                    <input class="form-control" type="number"
                                        placeholder="Tiempo de estar en el negocio (años)"
                                        formControlName="cli_time_bussiness" [ngClass]="{
                                        'is-invalid': submitted && form['cli_time_bussiness'].errors
                                      }" />
                                    <p *ngIf="submitted && form['cli_time_bussiness'].errors"
                                        class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="mb-3">
                                    <label class="form-label">Ubicacion exacta del negocio</label>
                                    <input class="form-control" type="text" placeholder="Ubicacion exacta del negocio"
                                        formControlName="cli_address_bussiness" [ngClass]="{
                                        'is-invalid': submitted && form['cli_address_bussiness'].errors
                                      }" />
                                    <p *ngIf="submitted && form['cli_address_bussiness'].errors"
                                        class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>


                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Departamento</label>
                                    <select class="form-control" formControlName="cli_dep_code_bussines" [ngClass]="{
                                        'is-invalid': submitted && form['cli_dep_code_bussines'].errors
                                      }" (ngModelChange)="onChangeDepBus($event)">
                                        <option value="" selected>--Seleccione--</option>
                                        <option *ngFor="let item of departaments" [value]="item.dep_code">
                                            {{item.dep_names}} </option>
                                    </select>
                                    <p *ngIf="submitted && form['cli_dep_code_bussines'].errors"
                                        class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Municipio</label>
                                    <select class="form-control" formControlName="cli_mun_code_bussines" [ngClass]="{
                                        'is-invalid': submitted && form['cli_mun_code_bussines'].errors
                                      }" (ngModelChange)="onChangeMunBus($event)">
                                        <option value="" selected>--Seleccione--</option>
                                        <option *ngFor="let item of bussines_nex_mun_municipalities"
                                            [value]="item.mun_code">
                                            {{item.mun_names}} </option>
                                    </select>
                                    <p *ngIf="submitted && form['cli_mun_code_bussines'].errors"
                                        class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Distrito</label>
                                    <select class="form-control" formControlName="cli_dis_code_bussines" [ngClass]="{
                                        'is-invalid': submitted && form['cli_dis_code_bussines'].errors
                                      }">
                                        <option value="" selected>--Seleccione--</option>
                                        <option *ngFor="let item of bussines_nex_dis_districts" [value]="item.dis_code">
                                            {{item.dis_names}} </option>
                                    </select>
                                    <p *ngIf="submitted && form['cli_dis_code_bussines'].errors"
                                        class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Ventas diarias</label>
                                    <input class="form-control" type="number" step="0.01" placeholder="Ventas diarias"
                                        formControlName="cli_daily_sell" [ngClass]="{
                                            'is-invalid': submitted && form['cli_daily_sell'].errors
                                          }" />
                                    <p *ngIf="submitted && form['cli_daily_sell'].errors"
                                        class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Costos de ventas diarias</label>
                                    <input class="form-control" type="number" step="0.01"
                                        placeholder="Costos de ventas diarias" formControlName="cli_daily_buy"
                                        [ngClass]="{
                                            'is-invalid': submitted && form['cli_daily_buy'].errors
                                          }" />
                                    <p *ngIf="submitted && form['cli_daily_buy'].errors" class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>


                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Margen de ganancia</label>
                                    <input class="form-control" type="number" step="0.01"
                                        placeholder="Margen de ganancia" formControlName="cli_daily_gain" [ngClass]="{
                                            'is-invalid': submitted && form['cli_daily_gain'].errors
                                          }" />
                                    <p *ngIf="submitted && form['cli_daily_gain'].errors"
                                        class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>


                            <div class="col-12 m-0 p-0">
                                <br>
                                <h6 class="m-0 p-0">Datos de Domicilio</h6>
                                <hr>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Departamento</label>
                                    <select class="form-control" formControlName="cli_dep_code" [ngClass]="{
                                        'is-invalid': submitted && form['cli_dep_code'].errors
                                      }" (ngModelChange)="onChangeDepDom($event)">
                                        <option value="" selected>--Seleccione--</option>
                                        <option *ngFor="let item of departaments" [value]="item.dep_code">
                                            {{item.dep_names}} </option>
                                    </select>
                                    <p *ngIf="submitted && form['cli_dep_code'].errors" class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Municipio</label>
                                    <select class="form-control" formControlName="cli_mun_code" [ngClass]="{
                                        'is-invalid': submitted && form['cli_mun_code'].errors
                                      }" (ngModelChange)="onChangeMunDom($event)">
                                        <option value="" selected>--Seleccione--</option>
                                        <option *ngFor="let item of address_nex_mun_municipalities"
                                            [value]="item.mun_code">
                                            {{item.mun_names}} </option>
                                    </select>
                                    <p *ngIf="submitted && form['cli_mun_code'].errors" class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Distrito</label>
                                    <select class="form-control" formControlName="cli_dis_code" [ngClass]="{
                                        'is-invalid': submitted && form['cli_dis_code'].errors
                                      }">
                                        <option value="" selected>--Seleccione--</option>
                                        <option *ngFor="let item of address_nex_dis_districts" [value]="item.dis_code">
                                            {{item.dis_names}} </option>
                                    </select>
                                    <p *ngIf="submitted && form['cli_dis_code'].errors" class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Direccion</label>
                                    <input class="form-control" type="text" placeholder="Direccion"
                                        formControlName="cli_address" [ngClass]="{
                                            'is-invalid': submitted && form['cli_address'].errors
                                          }" />
                                    <p *ngIf="submitted && form['cli_address'].errors" class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Teléfono fijo</label>
                                    <input class="form-control" type="text" placeholder="Teléfono"  mask="0000-0000"
                                        formControlName="cli_phone" [ngClass]="{
                                            'is-invalid': submitted && form['cli_phone'].errors
                                          }" />
                                    <p *ngIf="submitted && form['cli_phone'].errors" class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Tiempo de vivir en el lugar (años)</label>
                                    <input class="form-control" type="number" placeholder="Tiempo de vivir en el lugar"  
                                        formControlName="cli_time_alive" [ngClass]="{
                                            'is-invalid': submitted && form['cli_time_alive'].errors
                                          }" />
                                    <p *ngIf="submitted && form['cli_time_alive'].errors"
                                        class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-2">
                                <div class="mb-3">
                                    <label class="form-check-label" for="flexSwitchCheckDefault">Vivienda
                                        propia?</label>
                                    <div class="form-check form-switch checkbox-lg">
                                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                                            formControlName="cli_have_time_alive">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Nombre del arrendatario</label>
                                    <input class="form-control" type="text" placeholder="Nombre del arrendatario"
                                        formControlName="cli_tenant_name" [ngClass]="{
                                            'is-invalid': submitted && form['cli_tenant_name'].errors
                                          }" />
                                    <p *ngIf="submitted && form['cli_tenant_name'].errors"
                                        class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label class="form-label">Telefono del arrendatario</label>
                                    <input class="form-control" type="text" placeholder="Telefono del arrendatario" mask="0000-0000"
                                        formControlName="cli_tenant_phone" [ngClass]="{
                                            'is-invalid': submitted && form['cli_tenant_phone'].errors
                                          }" />
                                    <p *ngIf="submitted && form['cli_tenant_phone'].errors"
                                        class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer text-end">
                            <button class="btn btn-primary" *ngIf="!loading" type="button"
                                (click)="sendEmployeeDate()">Enviar</button>

                            <div class="loader-box" *ngIf="loading">
                                <div class="loader-1"></div>
                            </div>
                        </div>
                    </form>

                    <div *ngIf="navSelected=='2'">
                        <br>
                        <br>
                        <br>
                        <br>
                        <div *ngIf="id.length<10" class="alert alert-info"> <b>Atencion:</b> Se necesita registrar a un
                            cliente para agregarle descuentos</div>
                        <form *ngIf="id.length>10" [formGroup]="formDiscount">
                            <div class="row">
                                <div class="col-md-2">
                                    <label class="form-label">Nombre</label>
                                    <input class="form-control" type="text" placeholder="Ingresa el nombre" formControlName="ref_name"
                                        [ngClass]="{
                                                                                                        'is-invalid': submitted && formi['ref_name'].errors
                                      }" />
                                    <p *ngIf="submitted && formi['ref_name'].errors" class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                                <div class="col-md-3">
                                    <div class="mb-3">
                                        <label class="form-label">Vinculo</label>
                                        <select class="form-control" formControlName="rel_code"
                                            [ngClass]="{ 'is-invalid': submitted && formi['rel_code'].errors }">
                                            <option value="" selected>--Seleccione--</option>
                                            <option *ngFor="let item of relationship" [value]="item.rel_code"> {{item.rel_names}}
                                            </option>
                                        </select>
                                        <p *ngIf="submitted && formi['rel_code'].errors" class="is-invalid text-danger">
                                            Este dato es requerido
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <label class="form-label">Direcion</label>
                                    <input class="form-control" type="text" placeholder="Ingresa la Direccion" formControlName="ref_address"
                                        [ngClass]="{ 'is-invalid': submitted && formi['ref_address'].errors }" />
                                    <p *ngIf="submitted && formi['ref_address'].errors" class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                                <div class="col-md-2">
                                    <label class="form-label">Lugar de trabajo</label>
                                    <input class="form-control" type="text" placeholder="Ingresa el lugar de trabajo" formControlName="ref_work_place"
                                        [ngClass]="{ 'is-invalid': submitted && formi['ref_work_place'].errors }" />
                                    <p *ngIf="submitted && formi['ref_work_place'].errors" class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                                <div class="col-md-2">
                                    <label class="form-label">Teléfono</label>
                                    <input class="form-control" type="text" placeholder="Ingresa el teléfono" mask="0000-0000"
                                        formControlName="ref_phone" [ngClass]="{'is-invalid': submitted && formi['ref_phone'].errors }" />
                                    <p *ngIf="submitted && formi['ref_phone'].errors" class="is-invalid text-danger">
                                        Este dato es requerido
                                    </p>
                                </div>
                                <div class="col-1"> <br>
                                    <button class="btn btn-primary" *ngIf="!loading" type="button"
                                        (click)="sendEmployeeDiscount()">Agregar</button>

                                    <div class="loader-box" *ngIf="loading">
                                        <div class="loader-1"></div>
                                    </div>
                                </div>
                            </div>
                        </form>


                        <div *ngIf="id.length>10" class="table-responsive signal-table">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Vinculo</th>
                                        <th scope="col">Direccion</th>
                                        <th scope="col">Lugar<br>Trabajo</th>
                                        <th scope="col">Telefono</th>
                                        <th scope="col">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of discounts; let i=index">
                                        <th scope="row">{{i+1}}</th>
                                        <td>
                                            {{item.ref_name }}
                                        </td>
                                        <td>
                                            {{item.nex_rel_relationship.rel_names}}
                                            </td>
                                        <td>
                                            {{item.ref_address}}
                                        </td>
                                        <td>
                                            {{item.ref_work_place}}
                                        </td>
                                        <td>
                                            {{item.ref_phone }}
                                        </td>
                                        <td>
                                            <div class="action">
                                                <button class="btn btn-danger" (click)="deleteDiscount(item.ref_code)">
                                                    <i class="icon-trash"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody> 
                            </table>
                            <br>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>