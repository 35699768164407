import { Component } from '@angular/core';

@Component({
  selector: 'app-main', 
  templateUrl: './main.component.html',
  styles: ``
})
export class MainComponent {

}
